import * as React from "react"
import '../style/index.scss'

// markup
const NotFoundPage = () => {
  return (
    <main>
      Not Found
    </main>
  )
}

export default NotFoundPage
